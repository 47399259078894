import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { useAppState } from "@state";
import useHeaderData from "@staticQueries/HeaderQuery";

import { MobileHeader } from "@molecules";
import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";

const Menu = ({ text, url, children, theme }) => {
  const [visible, setVisible] = useState(false);
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  return (
    <li
      className="group relative flex h-full items-center py-4"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <AppLink
        to={url}
        onClick={() => setVisible(false)}
        className="flex items-center underline decoration-carnation/0 decoration-2 underline-offset-2 duration-300 group-hover:decoration-carnation"
      >
        <Text
          variant="link"
          className={classNames("duration-300", {
            "text-white": theme === "dark",
          })}
        >
          {text}
        </Text>
        {children?.length > 0 && (
          <Icon
            name="chevron"
            className="ml-1 h-2.5 w-2.5 rotate-90 text-carnation"
          />
        )}
      </AppLink>
      {children?.length > 0 && (
        <m.ul
          className={classNames(
            "absolute top-12 left-0 w-max space-y-4 bg-white p-4 shadow-lg",
            { "pointer-events-none": !visible }
          )}
          animate={visible ? "open" : "closed"}
          initial="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          {children?.map((subLink, _i) => {
            const { text: _text, url: _url } = subLink;
            return (
              <li key={_url}>
                <AppLink
                  to={_url}
                  onClick={() => setVisible(false)}
                  className="font-sans text-sm font-bold uppercase tracking-wider underline decoration-carnation/0 decoration-2 underline-offset-2 duration-300 hover:decoration-carnation"
                >
                  {_text}
                </AppLink>
              </li>
            );
          })}
        </m.ul>
      )}
    </li>
  );
};

const Header = () => {
  const [{ layout }, dispatch] = useAppState();

  // Mobile nav triggers
  const { hideNav, theme } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "4",
  };

  const { nav, login } = useHeaderData();

  return (
    <header
      className={classNames("absolute z-[100] w-full duration-300 ", {
        "bg-gradient-to-b from-white": theme === "light",
      })}
    >
      <div className="mx-auto max-w-7xl p-4">
        {/* BoardLead login button */}
        {login && (
          <div className="mb-2 hidden justify-end md:flex">
            <AppLink
              to={login.url}
              className={classNames(
                "font-sans text-sm font-bold uppercase tracking-wider underline decoration-carnation decoration-2 underline-offset-2 duration-300",
                {
                  "text-white": theme === "dark",
                }
              )}
            >
              {login.text || "BoardLead Login"}
            </AppLink>
          </div>
        )}
        <div className="flex items-center justify-between">
          <AppLink to="/">
            <div className="h-auto max-h-[3.5rem] w-28 duration-300 md:w-48">
              <Icon
                name={theme === "light" ? "logoDark" : "logoLight"}
                className="h-full w-full"
              />
            </div>
          </AppLink>
          <nav aria-label="Header navigation">
            <ul className="hidden items-center md:flex md:space-x-4 lg:space-x-12">
              {nav?.map((link, i) => {
                const { text, url, button } = link;
                if (button) {
                  return (
                    <li key={`header-link-${text}--${i + 1}`}>
                      <Button size="sm" to={url}>
                        {text}
                      </Button>
                    </li>
                  );
                }
                return (
                  <Menu
                    key={`header-link-${text}--${i + 1}`}
                    theme={theme}
                    {...link}
                  />
                );
              })}
            </ul>
            <button
              type="button"
              className="flex md:hidden"
              aria-label="Toggle mobile navigation"
              onClick={() => {
                dispatch({
                  type: hideNav ? "showNav" : "hideNav",
                });
              }}
            >
              {/* Hamburger Menu with animated SVG */}
              <span
                className={classNames("icon", {
                  "text-midnight": theme === "light",
                  "text-carnation": theme === "dark",
                })}
              >
                <svg width="28" height="28" viewBox="0 0 34 24">
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 2L2 2" },
                      open: { d: "M31 2L9 22" },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                  <m.path
                    {...iconStyles}
                    d="M32 12L2 12"
                    variants={{
                      closed: { opacity: 1 },
                      open: { opacity: 0 },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 22L2 22" },
                      open: { d: "M31 22L9 2" },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </svg>
              </span>
            </button>
          </nav>
        </div>
      </div>
      <MobileHeader nav={nav} login={login} />
    </header>
  );
};

Header.defaultProps = {};

export default Header;
